import axios from "axios"
import { ToastError } from '../../../utility/ToastMessages'
import url from '../../../Config'

export const getCountMessages = () => {
  return dispatch => {
    axios
      .get(url.ChatURL + "/messages/notSeen")
      .then(response => {
        
        dispatch({
          type: "MESSAGES_NOT_SEEN",
          count: response.data,
        })
      })
      .catch(err => console.log(err))
  }
}

export const getChats = () => {
  return dispatch => {
    axios
      .get(url.ChatURL + "/chats")
      .then(response => {
        dispatch({
          type: "GET_CONTACTS",
          contacts: response.data.contacts,
          chats: response.data.chats
        })
      })
      .catch(err => {
        if (err.response.status === 401) {
          dispatch({ type: "LOGOUT" })
          setTimeout(() => {
            ToastError('Sessão Expirada')
          }, 50);
        }
      })
  }
}

export const getContactChats = () => {
  return dispatch => {
    axios
      .get(url.ChatURL + "/chat/contacts")
      .then(response => {
        dispatch({
          type: "GET_CHAT_CONTACTS",
          chats: response.data
        })
      })
      .catch(err => console.log(err))
  }
}

export const togglePinned = (id, value) => {
  return dispatch => {
    axios
      .post(url.ChatURL + "/set-pinned/", {
        contactId: id,
        value
      })
      .then(response => {
        dispatch({
          type: "SET_PINNED",
          id,
          value
        })
      })
      .catch(err => console.log(err))
  }
}

export const sendMessage = (id, isPinned, text) => {
  if (text.length > 0) {
    return dispatch => {
      axios
      .post(url.ChatURL + "/sendMessage", {
        personReceivedID: id,
        textContent: text,
        isPinned
      })
      .then(response => {
        console.log(response)
          dispatch({
              type: "CHAT_SEND_MESSAGE",
              data: {
                personReceivedID: id,
                textContent: text,
                isPinned
              }
          })
      })
      .catch(err => { console.log(err) })
    }
  } else {
    return
  }
}

export const changeStatus = status => {
  return dispatch => {
    dispatch({
      type: "CHANGE_STATUS",
      status
    })
  }
}

export const searchContacts = query => {
  return dispatch => {
    dispatch({
      type: "SEARCH_CONTACTS",
      query
    })
  }
}

export const markSeenAllMessages = id => {
  return dispatch => {
    axios
      .post(url.ChatURL + "/" + id + "/seen")
      .then(response => {
        dispatch({
          type: "MARK_AS_SEEN",
          id
        })
      })
      .catch(err => console.log(err))
  }
}

export const addChatContact = data => {
  return dispatch => {
    dispatch({
      type: "ADD_CHAT_MESSAGE",
      data
    })
  }
}

export const updateMessagesCount = quantity => {
  console.log('UPDATE_MESSAGE_COUNT')
  return dispatch => {
    dispatch({
      type: "UPDATE_MESSAGE_COUNT",
      quantidadeMensagens: quantity
    })
  }
}



