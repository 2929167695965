import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import Lozenge from '@atlaskit/lozenge';
import { Querys } from '../../../../database/base';
import Tooltip from '@atlaskit/tooltip';
import CrossIcon from '@atlaskit/icon/glyph/cross'
import Config from '../../../../Config';
const userKey = '_suricatoo_user'
const user = JSON.parse(localStorage.getItem(userKey))
export default class Attachments extends Component {
    render() {
        return (
            <div className='mt-1 coll-attachment'>
                <Row>
                    {this.props.attachments.map((item, index) => (
                        <Col md='auto' key={`attachment-${item.id}`}>
                            <Lozenge appearance='inprogress'>
                                <div className='d-flex'>
                                    <div className='attachment-remove' onClick={() =>
                                        Querys.Card.AttachmentDelete(item.id, item.fileID)
                                            .then(() => this.props.onDelete(index))}>
                                        <Tooltip content='Remover'>
                                            <CrossIcon size='small' />
                                        </Tooltip>
                                    </div>
                                    <Tooltip content={item.fileName}>
                                        <div className='attachment-link'>
                                            <a href={`${Config.ApiURL}/card/attachment/${item.id}/${item.fileID}?token=${user.access_token}`} target='_blank'
                                            download={item.fileName}>{item.fileName}</a>
                                        </div>
                                    </Tooltip>
                                </div>
                            </Lozenge>
                        </Col>
                    ))}
                </Row>
            </div>
        )
    }
}
