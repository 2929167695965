import React, { Component } from 'react'
import AvatarPessoa from '../../../../utility/AvatarPessoa';
import Comment, {
    CommentAction,
    CommentAuthor,
    CommentEdited,
    CommentTime,
} from '@atlaskit/comment';
import Tooltip from '@atlaskit/tooltip';
import { connect } from "react-redux"
import ReactQuill from 'react-quill';
import QuillEditor from '../Editor';
import { Querys } from '../../../../database/base';
import EmojiPicker from 'emoji-picker-react';
import InlineDialog from '@atlaskit/inline-dialog';
import Button from '@atlaskit/button';
import EmojiAddIcon from '@atlaskit/icon/glyph/emoji-add'
import InputFiles from 'react-input-files';
import Attachments from './Attachments';
import CrossIcon from '@atlaskit/icon/glyph/cross'

const userKey = '_suricatoo_user'
const user = JSON.parse(localStorage.getItem(userKey))

class CardComment extends Component {
    state = {
        id: 0,
        cardID: 0,
        edit: false,
        description: null,
        contentID: null,
        permission: null,
        emojiOpen: false,
        emojiReactions: [],
        attachments: []
    }

    componentWillMount() {
        this.setState({ ...this.props })
        Querys.Card.ContentFile(this.props.cardKey, this.props.contentID)
            .then(({ data }) => this.setState({ description: data }))
        Querys.Person.Permission().then(resp => this.setState({ permission: resp.data.data }))
    }

    onFileChange = event => {
        console.log(event)
        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let encoded = reader.result.toString()
                    resolve({ file: { name: file.name, type: file.type }, encoded });
                };
                reader.onerror = error => reject(error);
            });
        }
        getBase64(event[0]).then((result) =>
            Querys.Card.AttachmentComment(this.state.id, result))
            .then(({ data }) => {
                this.state.attachments.push(data)
                this.setState({ attachments: [...this.state.attachments] })
            })
    };

    render() {
        console.log(this.state.permission)
        let date = new Date(this.state.createdAt)
        if (this.state.createdAt !== this.state.updatedAt) date = new Date(this.state.updatedAt)

        let actions = []
        if (!this.state.edit && (user.codigo === this.state.personID || this.state.permission?.Permissao !== 'U')) {
            actions.push(
                <CommentAction onClick={() => this.setState({ edit: true })}>Editar</CommentAction>,
                <CommentAction onClick={() => this.props.onDelete(this.state.id)}>Excluir</CommentAction>,
                <CommentAction>
                    <InputFiles onChange={this.onFileChange}>
                        Anexar
                    </InputFiles>
                </CommentAction>)
        }
        if (!this.state.edit && this.state.emojiReactions?.length > 0) {
            actions.push(
                ...this.state.emojiReactions.map(e =>
                (
                    <CommentAction onClick={() => {
                        Querys.Card.EmojiRemove(this.state.id, e.unified)
                            .then(({ data }) => this.setState({ emojiReactions: data }))
                    }}>
                        <Tooltip content={e.tooltip}>
                            <span className='emoji'>{e.emoji} {e.count}</span>
                        </Tooltip>
                    </CommentAction>
                ))
            )
        }
        if (!this.state.edit) {
            actions.push(<CommentAction>
                <InlineDialog
                    testId='emoji-coment'
                    isOpen={this.state.emojiOpen}
                    placement='bottom-start'
                    onClose={() => this.setState({ emojiOpen: false })}
                    content={<div>
                        <div className='d-flex justify-content-between'>
                            <h6>Emojis</h6>
                            <Button className='atl-button' iconAfter={<CrossIcon />} onClick={() => this.setState({ emojiOpen: false })}></Button>
                        </div>
                        <EmojiPicker
                            onEmojiClick={(e) => {
                                this.setState({ emojiReactions: [...(this.state.emojiReactions ?? []), { emoji: e.emoji }] })
                                Querys.Card.EmojiAdd(this.state.id, { emoji: e.emoji, unified: e.unified })
                                    .then(({ data }) => this.setState({ emojiReactions: data }))
                            }}

                            emojiStyle='google'
                            searchDisabled
                            skinTonesDisabled
                            lazyLoadEmojis /></div>}
                >
                    <Button className='atl-button' onClick={() => this.setState({ emojiOpen: !this.state.emojiOpen })} iconAfter={<EmojiAddIcon size='small' />}></Button>
                </InlineDialog>
            </CommentAction>)
        }

        return (
            <Comment
                avatar={<AvatarPessoa size='md' noTooltip codigo={this.state.personID} />}
                author={<CommentAuthor>{this.state.person?.name}</CommentAuthor>}
                edited={<CommentEdited>{this.state.createdAt !== this.state.updatedAt && 'Editado'}</CommentEdited>}
                restrictedTo={user.codigo === this.state.personID || this.state.permission?.Permissao !== 'U' ? null : "Restrito ao usuário que criou"}
                time={<CommentTime>{date.toLocaleString()}</CommentTime>}
                content={<>{
                    this.state.edit === false ?
                        <div>
                            <ReactQuill
                                atValues={this.state.allPeople}
                                hashValues={this.state.allPeople}
                                className='ql-editor-buddle' readOnly={true} theme={'bubble'} value={this.state.description} />

                        </div> :
                        <QuillEditor
                            focus={this.state.edit}
                            atValues={this.props.allPeople}
                            hashValues={this.props.allPeople}
                            value={this.state.description}
                            onSave={(content) => {
                                this.setState({ description: content, edit: false })
                                Querys.Card.CommentSave(this.state.cardID, { description: content, contentID: this.props.contentID })
                            }}
                            onCancel={() => this.setState({ edit: false })}
                            placeholder="Clique aqui para incluir a descrição" />
                }

                    {this.state.attachments.length > 0 && (
                        <Attachments attachments={this.state.attachments} onDelete={(index) => {
                            this.state.attachments.splice(index, 1)
                            this.setState({ attachments: [...this.state.attachments] })
                        }} />
                    )}
                </>

                }
                actions={actions}
            />
        )
    }
}

export default CardComment