import React, { Component } from 'react'
import Button from '@atlaskit/button/standard-button';
import MoreIcon from '@atlaskit/icon/glyph/more';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import '../projects/backlog/projectBacklog.scss'
import Tooltip from '@atlaskit/tooltip';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import InlineEdit from '@atlaskit/inline-edit';
import Textfield from '@atlaskit/textfield';
import AttachmentIcon from '@atlaskit/icon/glyph/attachment'
import DepartmentIcon from '@atlaskit/icon/glyph/department'
import { Col, Label, Row } from 'reactstrap';
import ListItem, { actions } from '../projects/backlog/components/ListItem';
import { DragDropContext } from 'react-beautiful-dnd';
import {
    Collapse
} from 'reactstrap'
import ShareIcon from '@atlaskit/icon/glyph/share'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import Lozenge from '@atlaskit/lozenge';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import QuillEditor from './Editor';
import { Querys } from '../../../database/base';
import DropdownChangeCardType from './components/DropdownChangeCardType';
import TextColor from '../../../utility/Color';
import CardComments from './components/CardComments';
import CardHistory from './components/CardHistory';
import InputFiles from 'react-input-files';
import Attachments from './components/Attachments';
import ShareCard from '../projects/backlog/components/ShareCard';
import { history } from '../../../history';
import SetParent from '../projects/backlog/components/SetParent';
import LinkIcon from '@atlaskit/icon/glyph/link'
import DropdownWorkPlanStatus from '../projects/backlog/components/DropdownWorkPlanStatus';
import CardInfo from './components/CardInfo';
import Skeleton from 'react-loading-skeleton';
import IntegrationCodeCommit from './components/IntegrationCodeCommit';

const app = JSON.parse(localStorage.getItem('_suricatoo_app'))
const user = JSON.parse(localStorage.getItem('_suricatoo_user'))
let accessToken = user?.access_token ?? ''
if (accessToken !== '') {
    accessToken = `?p=${accessToken}`
}

export default class Card extends Component {
    state = {
        id: 0,
        cardKey: '',
        name: '',
        search: '',
        items: [],
        secundaryItemOpen: false,
        descriptionEdit: false,
        description: null,
        peopleSearch: [],
        workPlanStatus: [],
        people: [],
        allPeople: [],
        newComment: false,
        editComment: false,
        editingComment: false,
        cardComments: [],
        isOpen: false,
        commentDelete: 0,
        areaSelect: 0,
        selectedFile: null,
        attachments: [],
        share: false,
        addParent: false,
        loading: true
    }

    onClose = result => {
        if (this.props.onClose) this.props.onClose()
    }

    componentWillMount() {
        let cardKey = this.props.match?.params.cardKey
        cardKey = cardKey ?? this.props.cardSelected
        Querys.WorkPlan.Status().then(({ data }) => this.setState({ workPlanStatus: data ?? [] }))
        Querys.Card.GetByID(cardKey).then(({ data }) => {
            this.setState({ ...data, people: data.projectID > 0 ? data.project.people : [], loading: false, parentCardID: data.id })
            if (data.descriptionID)
                Querys.Card.ContentFile(data.cardKey, data.descriptionID)
                    .then(({ data }) => this.setState({ description: data }))
            if (data.projectID === 0 || !data.projectID)
                Querys.Person.List().then(({ data }) => this.setState({ people: data ?? [] }))
            Querys.Card.CommentList(data.id).then(({ data }) => this.setState({ cardComments: data ?? [] }))
        }).catch(() => {
            history.push(`/`)
        })

        Querys.Person.List().then(({ data }) => this.setState({ allPeople: data ?? [] }))
    }

    componentWillUnmount() {
        if (!this.state.descriptionEdit) return
        const content = this.state.reference?.current?.getEditor().getContents();
        if (content) {
            this.saveContent(content)
        }
    }

    saveContent(content) {
        this.setState({ description: content, descriptionEdit: false })
        Querys.Card.ChangeDescription(this.state.id, { description: content })
    }

    onFileChange = event => {
        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let encoded = reader.result.toString()
                    resolve({ file: { name: file.name, type: file.type }, encoded });
                };
                reader.onerror = error => reject(error);
            });
        }
        for (let index = 0; index < event.length; index++) {
            const file = event[index];
            getBase64(file).then((result) =>
                Querys.Card.Attachment(this.state.id, result))
                .then(({ data }) => {
                    this.state.attachments.push(data)
                    this.setState({ attachments: [...this.state.attachments] })
                })
        }
    };

    render() {
        const listItemAction = actions(this, 'listItem')
        return (
            <div className='card-content'>
                {this.state.loading && <Skeleton height={35} width='100%' />}
                <Row>
                    <Col md='8'>
                        {this.state.loading && <Skeleton height={35} width='100%' count={5} className='mt-1' />}
                        {!this.state.loading &&
                            <React.Fragment>
                                <div className='d-flex'>
                                    <Breadcrumbs>
                                        {this.state.projectID ? <>
                                            <BreadcrumbsItem href={"/management/projects" + accessToken} text="Projetos" target='_blank' />
                                            <BreadcrumbsItem
                                                target='_blank'
                                                href={"/management/projects/backlog/" + this.state.projectID + accessToken}
                                                text={<Lozenge style={{
                                                    backgroundColor: this.state.project.color,
                                                    color: TextColor(this.state.project.color)
                                                }}>{this.state.project.name}</Lozenge>} />
                                        </> : <BreadcrumbsItem href="/backlog" text="Backlog" />}
                                        <BreadcrumbsItem text={(
                                            <DropdownChangeCardType
                                                cardType={this.state.cardType}
                                                cardKey={this.state.cardKey}
                                                name={this.state.name}
                                                subCards={this.state.subCards.length}
                                                onClick={(cardType) => {
                                                    this.setState({ cardType })
                                                    Querys.Card.UpdateFields(this.state.id, { cardType })
                                                }} />
                                        )} />
                                    </Breadcrumbs>
                                </div>
                                <div>
                                    <div>
                                        <InlineEdit
                                            defaultValue={this.state.name}
                                            editView={({ errorMessage, ...fieldProps }) => (
                                                <Textfield {...fieldProps} autoFocus className='card-title-name' />
                                            )}
                                            readView={() => (
                                                <div className='card-read-view'>
                                                    <h1>{this.state.name || 'Insira a descrição do card aqui'}</h1>
                                                </div>
                                            )}
                                            onConfirm={(value) => {
                                                this.setState({ name: value })
                                                Querys.Card.UpdateFields(this.state.id, { name: value })
                                            }}
                                        />
                                    </div>
                                    <div className='d-flex card-top-separator'>
                                        <InputFiles multiple accept="*" onChange={this.onFileChange}>
                                            <Button className='atl-button card-button-separator'
                                                iconBefore={<AttachmentIcon label="more" />}>
                                                <b>Anexar</b>
                                            </Button>
                                        </InputFiles>
                                        {/* {(!this.state.parent ||
                                            this.state.cardType === 'epic' ||
                                            this.state.cardType === 'history' ||
                                            this.state.cardType === 'impediment') && */}
                                        <Button className='atl-button'
                                            isDisabled={this.state.subCards.length > 0}
                                            onClick={() => this.setState({ secundaryItemOpen: true })}
                                            iconBefore={<DepartmentIcon label="more" />}>
                                            <b>Adicionar item secundário</b>
                                        </Button>
                                    </div>
                                    {this.state.attachments.length > 0 && (
                                        <Attachments attachments={this.state.attachments} onDelete={(index) => {
                                            this.state.attachments.splice(index, 1)
                                            this.setState({ attachments: [...this.state.attachments] })
                                        }} />
                                    )}
                                    <Collapse isOpen={this.state.secundaryItemOpen || this.state.subCards.length > 0}>
                                        <div className='mt-2'>
                                            <DragDropContext onDragEnd={listItemAction.onDragEnd}>
                                                {this.state.allPeople.length > 0 &&
                                                    <ListItem card opened disableMultiSelect disableReload
                                                        peopleSearch={this.state.peopleSearch}
                                                        search=''
                                                        destinyImport={'card_' + this.state.id}
                                                        droppableId='listItem' title='Itens filhos'
                                                        data={this.state.listItem ?? {}}
                                                        project={this.state.project}
                                                        people={this.state.project ? this.state.project.people : this.state.allPeople}
                                                        allPeople={this.state.allPeople}
                                                        workPlanStatus={this.state.workPlanStatus}
                                                        {...listItemAction} />}
                                            </DragDropContext>
                                        </div>
                                    </Collapse>
                                    <div className='card-top-separator mt-2'>
                                        <Label><b className='card-title-area'>Documentação</b></Label>
                                        {!this.state.descriptionEdit ?
                                            <div className='quill-edit' onClick={() => this.setState({ descriptionEdit: true })}>
                                                {this.state.description ?
                                                    <ReactQuill
                                                        atValues={this.state.allPeople}
                                                        hashValues={this.state.allPeople}
                                                        className='ql-editor-buddle' readOnly={true} theme={'bubble'} value={this.state.description} /> :
                                                    <small style={{ padding: '10px' }}>Clique aqui para inserir a descrição</small>}
                                            </div> :
                                            <QuillEditor
                                                atValues={this.state.allPeople}
                                                hashValues={this.state.allPeople}
                                                value={this.state.description}
                                                onSave={(content) => this.saveContent(content)}
                                                onCancel={() => this.setState({ descriptionEdit: false })}
                                                placeholder="Clique aqui para incluir a descrição"
                                                setReference={(reference) => this.setState({ reference })}
                                            />
                                        }
                                    </div>

                                    <div className='mt-3 mb-2'>
                                        <Label><b className='card-title-area'>Atividade</b></Label>
                                        <div className='d-flex card-activity'>
                                            <Button className='atl-button'
                                                isSelected={this.state.areaSelect === 0} spacing="compact"
                                                onClick={() => this.setState({ areaSelect: 0 })}>
                                                <b>Comentários</b>
                                            </Button>
                                            <Button className='atl-button'
                                                isSelected={this.state.areaSelect === 1} spacing="compact"
                                                onClick={() => this.setState({ areaSelect: 1 })}>
                                                <b>Histórico</b>
                                            </Button>
                                            {this.state.id > 0 && this.state.codeCommits.length > 0 &&
                                                <Button className='atl-button'
                                                    isSelected={this.state.areaSelect === 2} spacing="compact"
                                                    onClick={() => this.setState({ areaSelect: 2 })}>
                                                    <b>Integrações</b>
                                                </Button>}

                                        </div>
                                    </div>
                                    {(this.state.id > 0 && this.state.areaSelect === 0) &&
                                        <CardComments id={this.state.id} cardKey={this.state.cardKey} />}
                                    {(this.state.id > 0 && this.state.areaSelect === 1) &&
                                        <CardHistory id={this.state.id} cardKey={this.state.cardKey} />}
                                    {this.state.id > 0 && this.state.areaSelect === 2 && <IntegrationCodeCommit {...this.state} />}
                                </div>
                            </React.Fragment>}
                    </Col>
                    <Col md='4'>
                        {this.state.loading && <Skeleton height={35} width='100%' className='mt-1' count={5} />}
                        {!this.state.loading &&
                            <React.Fragment>
                                <div className='d-flex justify-content-between mb-1'>
                                    <div></div>
                                    <div className='d-flex card-right-actions'>
                                        {this.state.parent &&
                                            <Tooltip content={'Pai: ' + this.state.parent.cardKey + ': ' + this.state.parent.name} position='top'>
                                                <div className='card-link-parent'>
                                                    <a href={"/card/" + this.state.parent.cardKey + accessToken} target='_black'>
                                                        <LinkIcon size='small' />
                                                        <b>{this.state.parent.cardKey}</b>
                                                        <small style={{ paddingLeft: '3px' }}>({this.state.parent.name})</small>
                                                    </a>
                                                </div>
                                            </Tooltip>}
                                        {this.state.id &&
                                            <Tooltip content='Compartilhar' position='top' >
                                                <Button className='atl-button'
                                                    onClick={() => this.setState({ share: true })}
                                                    iconBefore={<ShareIcon label="more" />}
                                                />
                                            </Tooltip>}
                                        <Tooltip content='Ações' position='top'>
                                            <DropdownMenu
                                                placement='bottom-end'
                                                trigger={({ triggerRef, testId, isSelected, ...props }) => (
                                                    <Button className='atl-button'
                                                        {...props}
                                                        iconBefore={<MoreIcon label="more" />}
                                                        ref={triggerRef}
                                                    />
                                                )}>
                                                <DropdownItemGroup>
                                                    {!this.state.parent &&
                                                        <DropdownItem onClick={() => this.setState({ addParent: true })}>
                                                            <span>Vincular pai</span>
                                                        </DropdownItem>}

                                                    {this.state.parent && <DropdownItem onClick={() => {
                                                        Querys.Card.UpdateFields(this.state.id, { removeParent: true })
                                                        this.setState({ parent: null })
                                                    }}>
                                                        <span>Desvincular pai (<b>{this.state.parent.cardKey}</b>)</span>
                                                    </DropdownItem>}

                                                    <DropdownItem onClick={() =>
                                                        Querys.Card.Clone(this.state.id)
                                                            .then(({ data }) => {
                                                                history.push('/card/' + data.cardKey)
                                                            })}>
                                                        <span>Clonar</span>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() =>
                                                        Querys.Card.Delete(this.state.id)
                                                            .then(() => {
                                                                if (this.state.projectID)
                                                                    history.push('/management/projects/backlog/' + this.state.projectID)
                                                                else
                                                                    history.push('/backlog')
                                                            })}>
                                                        <span>Excluir</span>
                                                    </DropdownItem>
                                                </DropdownItemGroup>
                                            </DropdownMenu>
                                        </Tooltip>
                                        {document.location.pathname.indexOf('/card/') < 0 &&
                                            <Tooltip content='Fechar' position='top'>
                                                <Button className='atl-button' onClick={this.onClose}
                                                    iconBefore={<CrossIcon label="more" />}
                                                />
                                            </Tooltip>}
                                    </div>
                                </div>
                                <div className='mb-1'>
                                    {this.state.id > 0 && this.state.workPlanStatus &&
                                        <DropdownWorkPlanStatus
                                            spacing='default'
                                            data={this.state.workPlanStatus}
                                            status={this.state.workPlanStatusType ?? 'todo'}
                                            onClick={(status) => {
                                                Querys.Card.UpdateFields(this.state.id, { workPlanStatusType: status })
                                                this.setState({ workPlanStatusType: status })
                                            }} />
                                    }
                                </div>
                                {this.state.id > 0 && <CardInfo {...this.state} onChange={e => this.setState({ tags: e })} />}
                            </React.Fragment>}
                    </Col>
                </Row>
                <ShareCard
                    id={this.state.id}
                    open={this.state.share}
                    data={this.state.allPeople}
                    search={this.state.search}
                    onToogle={() => this.setState({ share: !this.state.share })}
                    onChange={e => this.setState({ search: e.target.value })}
                    onCheck={(item) => {
                        item.selected = !item.selected
                        this.setState({ ...this.state })
                    }}
                    onShared={() => this.setState({
                        share: false,
                        allPeople: this.state.allPeople.map(x => {
                            x.selected = false
                            return x
                        })
                    })} />
                {this.state.addParent &&
                    <SetParent open={this.state.addParent}
                        project={this.state.projectID}
                        onToogle={() => this.setState({ addParent: !this.state.addParent })}
                        parent={this.state.id}
                        cardType={this.state.cardType}
                    />}

            </div>
        )
    }
}
