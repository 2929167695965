import React, { Component } from 'react'
import { Label } from 'reactstrap';
import Button from '@atlaskit/button/standard-button';
import BitbucketCommitsIcon from '@atlaskit/icon/glyph/bitbucket/commits'

import azure from './img/azuredevops.webp'
import github from './img/github.png'
import gitlab from './img/gitlab.png'
import AvatarPessoa from '../../../../utility/AvatarPessoa';
import Tooltip from '@atlaskit/tooltip';

import { SimpleTag as Tag } from '@atlaskit/tag';

const app = JSON.parse(localStorage.getItem('_suricatoo_app'))
const user = JSON.parse(localStorage.getItem('_suricatoo_user'))
let accessToken = user?.access_token ?? ''
if (accessToken !== '') {
    accessToken = `?p=${accessToken}`
}

export default class IntegrationCodeCommit extends Component {
    render() {
        const { codeCommits } = this.props
        return (
            <div className='mt-1 mb-2'>
                <div className='mt-1'>
                    {codeCommits && codeCommits.length > 0 && <div>
                        <table className='w-100'>
                            <thead>
                                <tr>
                                    <th style={{ width: '35px' }}></th>
                                    <th style={{ width: '85px' }}>Codigo</th>
                                    <th style={{ width: '120px' }}>Data</th>
                                    <th style={{ width: '150px' }}>Usuário</th>
                                    {this.props.showCard && <th style={{ width: '85px' }}>Card</th>}
                                    <th>Comentario</th>
                                </tr>
                            </thead>
                            <tbody>
                                {codeCommits.map((item, index) => (<tr className={`integration-grid-tr ${index % 2 === 0 ? 'integration-grid-row-color' : ''}`} key={`commit-${item.id}`}>
                                    <td>
                                        <div style={{ paddingLeft: item.type === 'github' ? '0px' : '6px' }}>
                                            <img src={item.type === 'github' ? github : item.type === 'azuredevops' ? azure : gitlab}
                                                alt='github' width={item.type === 'github' ? 30 : 20} />
                                        </div>
                                    </td>
                                    <td>
                                        <span className='link-integration-tag'>
                                            <a href={item.url} target='_blank'>{item.commitID.substring(0, 10)}</a>
                                        </span>
                                    </td>
                                    <td><small>{new Date(item.timestamp).toLocaleString()}</small></td>
                                    <td className='integration-grid-avatar'>
                                        <div className='d-flex'>
                                            <AvatarPessoa codigo={item.personCode} nome={item.authorEmail} />
                                            <Tooltip content={item.authorEmail}>
                                                <label style={{ paddingTop: '5px' }}>{item.authorName}</label>
                                            </Tooltip>
                                        </div>
                                    </td>
                                    {this.props.showCard && <td>
                                        {item.card && item.card?.cardKey && <a href={`/card/${item.card.cardKey}${accessToken}`} target='_blank'>{item.card.cardKey}</a>}
                                    </td>}
                                    <td>
                                        {item.message}</td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        )
    }
}
