import React, { Component } from 'react'
import Modal, { ModalBody, ModalTransition, ModalHeader, ModalTitle, ModalFooter } from '@atlaskit/modal-dialog';
import $ from 'jquery'

export default class  ModalSuricatoo extends Component {
    state = {
        opened: null
    }

    componentDidUpdate() {
        // if (this.props.layoutControl)
        //     return
        // if (this.props.open === true && this.state.opened === null && !$('.main-menu').hasClass('vertial-layout-index')) {
        //     $('.main-menu').addClass('vertial-layout-index')
        //     setTimeout(() => {
        //         this.setState({ opened: true })
        //     }, 10);
        // } else if (this.state.opened === true && this.props.open === false && $('.main-menu').hasClass('vertial-layout-index')) {
        //     $('.main-menu').removeClass('vertial-layout-index')
        //     setTimeout(() => {
        //         this.setState({ opened: null })
        //     }, 10);
        // }
    }
    render() {
        return (
            <ModalTransition>
                {this.props.open &&
                    <Modal width={this.props.width} onClose={this.props.onClose} shouldScrollInViewport={true} autoFocus={this.props.autoFocus ?? true}>
                        {this.props.header &&
                            <ModalHeader>
                                <ModalTitle appearance={this.props.appearance ?? ''}>
                                    {this.props.header}
                                </ModalTitle>
                            </ModalHeader>}
                        <ModalBody>
                            {this.props.children}
                        </ModalBody>
                        {this.props.footer &&
                            <ModalFooter>
                                {this.props.footer}
                            </ModalFooter>}
                    </Modal>}
            </ModalTransition>
        )
    }
}
