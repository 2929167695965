import url from '../../../Config'
import axios from 'axios'
import { toast } from 'react-toastify'
import { ToastSuccess, ToastError } from '../../../utility/ToastMessages'
import { floatFormat } from '../../../utility/moneyFormat'

import moment from 'moment'
const dataInicio = new Date(moment(moment().add(-1, 'month').startOf('day').startOf('month').format('YYYY-MM-DD hh:mm')).startOf('day'))
const dataFim = new Date(moment().startOf('day'))

export const carregarPessoaPorCodigo = (codigo, handlerOk, handlerError) => {
    return dispatch => {
        axios.get(`${url.RouterUrl}/api/people/byId/${codigo}`)
            .then(res => {
                const resp = {
                    "data": {
                        "status": "success",
                        "message": "Requisição realizada.",
                        "data": {
                            AvatarColor: res.data.AvatarColor ?? '#000',
                            Arquivado: res.data.arquivado,
                            CapacidadeSemana: res.data.CapacidadeSemana,
                            Codigo: res.data.Codigo,
                            CodigoEmpresa: res.data.CodigoEmpresa,
                            CodigosGrupo: res.data.CodigosGrupo && res.data.CodigosGrupo.length > 0 ? res.data.CodigosGrupo : [],
                            CodigosProjeto: res.data.CodigosProjeto,
                            CustoHora: res.data.CustoHora,
                            DataAtualizacao: res.data.DataAtualizacao,
                            DataCriacao: res.data.DataCriacao,
                            DataExclusao: null, //não vem
                            Email: res.data.Email,
                            Foto: null, //não vem
                            Nome: res.data.Nome,
                            Permissao: res.data.Permissao,
                            Semaforo: res.data.Semaforo,
                            SenhaRoot: null, //não vem
                            Tipo: res.data.Tipo,
                            Welcome: res.data.Welcome,
                            visualiza_custo: res.data.VisualizaCusto,
                            Tracking: res.data.Tracking,
                            TrackingUntil: res.data.TrackingUntil,
                            TrackingLoginExpire: res.data.TrackingLoginExpire,
                            peopleCosts: res.data.PessoaCusto.map(m => {
                                let cost = m
                                cost.hourCost = cost.hourCost.toFixed(2);
                                cost.id = cost.Codigo
                                return cost
                            }),
                            saleValue: res.data.saleValue,
                            saleValueCostCenter: res.data.saleValueCostCenter
                        }
                    },
                    "status": res.status,
                    "statusText": res.statusText,
                    "headers": res.headers,
                    "config": res.config,
                    "request": res.request
                }

                if (resp.status === 200 && resp.data.status === "success") {
                    if (!resp.data.data.CodigosGrupo) {
                        resp.data.data.CodigosGrupo = []
                    }
                    if (handlerOk)
                        handlerOk(resp)
                    dispatch({ type: 'ATUALIZAR_PESSOA_CODIGO', data: resp.data.data })
                } else {
                    ToastError(resp.response.data)
                    if (handlerError)
                        handlerError(resp)
                }
                return resp
            })
            .catch(resp => {
                ToastError(resp.response.data)
                if (handlerError)
                    handlerError(resp)
                dispatch({ type: 'NEW_ERROR', error: { message: resp.response.data.message } })
            })
    }
}

/*export const carregarPessoaPorCodigo = (codigo, handlerOk, handlerError) => {
    return dispatch => {
        axios.get(`${url.RouterUrl}/api/back/pessoa/${codigo}`)
            .then(resp => {
                console.log(JSON.stringify(resp))
                if (resp.status === 200 && resp.data.status === "success") {
                    if (!resp.data.data.CodigosGrupo) {
                        resp.data.data.CodigosGrupo = []
                    }
                    if (handlerOk)
                        handlerOk(resp)
                    dispatch({ type: 'ATUALIZAR_PESSOA_CODIGO', data: resp.data.data })
                } else {
                    ToastError(resp.response.data)
                    if (handlerError)
                        handlerError(resp)
                }
            })
            .catch(resp => {
                ToastError(resp.response.data)
                if (handlerError)
                    handlerError(resp)
            })
    }
}*/

export const carregarPessoas = (handlerOk, handlerError) => {
    return dispatch => {
        //axios.get(`${url.RouterUrl}/api/people`)
        axios.get(`${url.RouterUrl}/api/back/pessoa`)
            .then(resp => {
                /*const data = resp.data.map( m => {
                    return {
                        "Codigo": m.Codigo,
                        "CodigoEmpresa": m.CodigoEmpresa,
                        "DataCriacao": m.DataCriacao,
                        "DataAtualizacao": m.DataAtualizacao,
                        "DataExclusao": null,
                        "Nome": m.Nome,
                        "Email": m.Email,
                        "SenhaRoot": "",
                        "Welcome": m.Welcome,
                        "Arquivado": m.arquivado,
                        "Tipo": m.Tipo,
                        "Permissao": m.Permissao,
                        "visualiza_custo": m.VisualizaCusto,
                        "CustoHora": m.CustoHora,
                        "CapacidadeSemana": m.CapacidadeSemana,
                        "Semaforo": m.Semaforo,
                        "Foto": null,
                        "CodigosGrupo": m.CodigosGrupo,
                        "CodigosProjeto": m.CodigosProjeto
                    }
                })*/

                if (resp.status === 200 && resp.data.status === "success") {
                    if (!resp.data.data.CodigosGrupo) {
                        resp.data.data.CodigosGrupo = []
                    }
                    if (handlerOk)
                        handlerOk(resp)
                    dispatch({ type: 'CARREGAR_PESSOAS', data: resp.data.data })
                } else {
                    ToastError(resp.response.data)
                    if (handlerError)
                        handlerError(resp)
                }
            })
            .catch(resp => {

                ToastError(resp.response.data)
                if (handlerError)
                    handlerError(resp)
                dispatch({ type: 'NEW_ERROR', error: { message: resp.response.data.message } })
            })
    }
}


export const carregarPessoasArquivadas = (handlerOk, handlerError) => {
    return dispatch => {
        axios.get(`${url.RouterUrl}/api/people/archive`)
            .then(resp => {
                if (resp && resp.status === 200) {
                    const data = resp && resp.data && resp.data.length > 0 ? resp.data.map(m => {
                        return {
                            Arquivado: m.Arquivado,
                            CapacidadeSemana: 0, //não vem
                            Codigo: m.Codigo,
                            CodigoEmpresa: m.CodigoEmpresa,
                            CodigosGrupo: [], //não vem
                            CodigosProjeto: [], //não vem
                            CustoHora: m.CustoHora,
                            DataAtualizacao: "0001-01-01T00:00:00Z", //não vem
                            DataCriacao: m.DataCriacao,
                            DataExclusao: null, //não vem
                            Email: m.Email,
                            Foto: null, //não vem
                            Nome: m.Nome,
                            Permissao: m.Permissao,
                            Semaforo: m.Semaforo,
                            Senha: "", //não vem
                            Tipo: m.Tipo,
                            Welcome: m.Welcome,
                            visualiza_custo: m.VisualizaCusto
                        }
                    }) : []
                    if (handlerOk)
                        handlerOk(resp)
                    dispatch({ type: 'CARREGAR_PESSOAS', data: data })
                } else {
                    ToastError(resp.response.data)
                    if (handlerError)
                        handlerError(resp)
                }
            })
            .catch(resp => {

                ToastError(resp.response.data)
                if (handlerError)
                    handlerError(resp)
                dispatch({ type: 'NEW_ERROR', error: { message: resp.response.data.message } })
            })
    }
}

export const deletarPessoa = (pessoa, handlerOk, handlerError) => {
    return dispatch => {
        //dispatch({ type: 'REMOVER_PESSOA', data: pessoa.Codigo })
        axios.delete(`${url.RouterUrl}/api/people/${pessoa.Codigo}`)
            .then(resp => {
                window.location.href = '/management/team'
            })
            .catch(resp => {
                if (handlerError)
                    handlerError(resp)
                ToastError(resp.response.data && resp.response.data.Message)
                dispatch({ type: 'NEW_ERROR', error: { message: resp.response.data.message } })
            })
    }
}

export const arquivarPessoa = (pessoa, handlerOk, handlerError) => {
    console.log(pessoa)
    return dispatch => {
        axios.put(`${url.RouterUrl}/api/people/${pessoa.Codigo}/${typeof pessoa.Arquivado === 'undefined' || pessoa.Arquivado ? 'unarchive' : 'archive'}`)
            .then(resp => {

                if (resp.status === 200) {
                    ToastSuccess('Pessoa arquivada com sucesso')
                    dispatch({ type: 'REMOVER_PESSOA', data: pessoa.Codigo })
                    if (handlerOk)
                        handlerOk(resp)
                } else {
                    ToastError(resp.response.data)
                    if (handlerError)
                        handlerError(resp)
                }
            })
            .catch(resp => {
                ToastError(resp.response.data)
                if (handlerError)
                    handlerError(resp)
                dispatch({ type: 'NEW_ERROR', error: { message: resp.response.data.message } })
            })
    }
}

export const carregarPendencias = (total) => {
    const userKey = '_suricatoo_user'
    const user = JSON.parse(localStorage.getItem(userKey))

    return dispatch => {
        if (typeof total !== 'undefined') {
            dispatch({
                type: 'QUANTIDADE_PENDENCIAS',
                QuantidadePendencias: total > 0 ? total : null
            })
        } else {
            axios.get(`${url.RouterUrl}/api/management/pendingPeople/${user.codigo}/${moment(dataInicio).format('YYYY-MM-DD')}/${moment(dataFim).format('YYYY-MM-DD')}/count`)
                .then(resp => {

                    if (resp.status === 200) {
                        dispatch({
                            type: 'QUANTIDADE_PENDENCIAS',
                            QuantidadePendencias: resp.data.total ? resp.data.total : null
                        })
                    } else {
                        ToastError(resp.response.data.message)
                        dispatch({ type: 'NEW_ERROR', error: { message: resp.response.data.message } })
                    }
                })
                .catch(resp => {
                    ToastError(resp.response.data.message)
                    dispatch({ type: 'NEW_ERROR', error: { message: resp.response.data.message } })
                })

        }
    }
}

export const atualizaQuantidadePendencias = (quantidade) => {
    return dispatch => {
        dispatch({
            type: 'ATUALIZA_QUANTIDADE_PENDENCIAS',
            QuantidadePendencias: quantidade
        })

    }
}

export const detalhesUsuario = (data) => {

    return dispatch => {
        dispatch({
            type: 'DETALHES_USUARIO',
            DetalhesUsuario: data
        })

    }
}
