export const getCache = (key, defaultObj) => {
    const js = JSON.parse(localStorage.getItem(key))
    return { ...defaultObj, ...js }
}

export const setCache = (key, obj) => {
    localStorage.setItem(key, JSON.stringify(obj))
}

export const delCache = (key) => {
    localStorage.removeItem(key)
}