import url from '../../../Config'
import axios from 'axios'
import { toast } from 'react-toastify'
import { history } from '../../../history'
import { errorHandle } from '../../../utility/error-handle'
const userKey = '_suricatoo_user'

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}

async function carregarConfiguracoes() {
  const configs = await axios.get(`${url.RouterUrl}/api/management/company`)
    .then(resp => {
      if (resp.status === 200) {

        return {
          preferencesConfig: {
            CLOSE_WEEK: resp.data && resp.data.Config ? resp.data.Config.CLOSE_WEEK : false,
            DONT_PAY_CONTESTEDS: resp.data && resp.data.Config ? resp.data.Config.DONT_PAY_CONTESTEDS : false
          }
        }
      } else {
        return null
      }
    })
    .catch(resp => {
      return null
    })
  return configs
}

export const login = user => {
  return dispatch => {
    axios.post(`${url.RouterUrl}/api/auth/login`, user)
      .then(async resp => {
        if (resp.status === 200) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${resp.data.access_token}`
          // if (window.appOnline === true) {
            axios.post(`http://localhost:59871/login`, { token: resp.data.access_token })
              .then((data) => {
                dispatch({ type: 'APP_CONFIG', login: data })
              })
              .catch((resp) => {
                dispatch({ type: 'APP_CONFIG', login: resp })
              })
          // }

          setTimeout(async () => {
            const configs = await carregarConfiguracoes()
            if (configs) {
              const userData = { ...resp.data, ...configs }
              localStorage.setItem(userKey, JSON.stringify(userData))
              dispatch({ type: "LOGIN", user: userData })
              if (resp.data.welcome) {
                history.push('/welcome')
              } else if (resp.data.recovery) {
                history.push({
                  pathname: '/changePassword',
                  state: { user: user }
                })
              }

            }
          }, 500);
        } else {
          toast.error("Usuário ou senha incorreto!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000
          })
        }
      })
      .catch(error => {
        const err = errorHandle(error)
        toast.error(err && err.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000
        })
      })
  }
}

export const setUserPreferences = () => {
  return dispatch => {
    setTimeout(async () => {
      const configs = await carregarConfiguracoes()
      //Caso existam configurações, alterá o localStorage e adiciona as configurações no redux
      if (configs) {
        const userStorage = { ...JSON.parse(localStorage.getItem(userKey)), ...configs }
        localStorage.setItem(userKey, JSON.stringify(userStorage))
        dispatch({ type: "PREFERENCES_CONFIG", configs })
      }
    }, 500);

  }
}

export const updateUser = user => {
  return dispatch => {
    dispatch({ type: 'UPDATE_USER', user: user })
  }
}

export const welcome = () => {
  return dispatch => {
    dispatch({ type: "WELCOME" })
  }
}

export const logout = () => {
  return dispatch => {
    dispatch({ type: "LOGOUT" })
  }
}

export const dismissMessageFlag = () => {
  return dispatch => {
    dispatch({ type: 'DISMISS_MESSAGE_FLAG' })
  }
}

export const newMessageFlag = message => {
  return dispatch => {
    dispatch({ type: 'NEW_MESSAGE_FLAG', message })
  }
}

export const updateMenu = () => {
  return dispatch => {
    dispatch({ type: 'UPDATE_MENU' })
  }
}

export const newError = error => {
  return dispatch => {
    dispatch({ type: 'NEW_ERROR', error })
  }
}

export const newMessageSuccess = (message) => {
  return dispatch => {
    dispatch({ type: 'NEW_MESSAGE_SUCCESS', message })
  }
}

export const cardCreated = () => {
  return dispatch => {
    dispatch({ type: 'CARD_CREATED' })
  }
}

export const startActivity = (cardStart) => {
  return dispatch => {
    dispatch({ type: 'START_ACTIVITY', cardStart: cardStart })
  }
}