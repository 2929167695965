import { delCache, getCache, setCache } from './base'
import * as omboarding from './omboarding'

export const Cache = {
    omboarding: omboarding,
    userPermitedDocument: [3, 4, 11, 459],
    user: () => {
        const key = '_suricatoo_user'
        return {
            get: () => getCache(key),
        }
    },
    document: () => {
        const key = '_suricatoo_documents_'
        return {
            del: (id) => delCache(key + id),
            get: (id) => getCache(key + id),
            set: (id, data) => setCache(key + id, data),
        }
    },
    createCard: (userId) => {
        const key = '_suricatoo_createCard_' + userId
        return {
            get: () => getCache(key),
            set: (data) => setCache(key, data),
        }
    },
    createCardProject: (userId) => {
        const key = '_minzi_createCard_project_' + userId
        return {
            get: () => getCache(key),
            set: (data) => setCache(key, data),
        }
    }
    
}