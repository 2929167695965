import React, { Component } from 'react'
import Comment from '@atlaskit/comment';
import AvatarPessoa from '../../../../utility/AvatarPessoa';
import QuillEditor from '../Editor';
import { Querys } from '../../../../database/base';
import CardComment from './CardComment';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ModalTitle } from 'react-bootstrap';
import Button from '@atlaskit/button';

const userKey = '_suricatoo_user'
const user = JSON.parse(localStorage.getItem(userKey))

export default class CardComments extends Component {
    state = {
        newComment: false,
        editComment: false,
        allPeople: [],
        cardComments: [],
        description: null,
    }

    componentWillMount() {
        this.setState({ ...this.props })
        Querys.Person.List().then(({ data }) => this.setState({ allPeople: data ?? [] }))
        Querys.Card.CommentList(this.props.id).then(({ data }) => this.setState({ cardComments: data ?? [] }))
        document.addEventListener("keydown", this.keyPress, false)
    }

    keyPress = (e) => {
        if (e.ctrlKey && e.code === "KeyM") this.setState({ newComment: true })
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyPress, false)
    }

    closeModal = () => {
        this.setState({ isOpen: false })
    }

    render() {
        return (
            <div className='mb-3'>
                <Comment
                    avatar={<AvatarPessoa size='md' noTooltip codigo={user?.codigo ?? 0} />}
                    content={<>
                        <QuillEditor
                            cleanOnSave
                            focus={this.state.newComment}
                            showToolbar={this.state.editComment}
                            atValues={this.state.allPeople}
                            hashValues={this.state.allPeople}
                            value={this.state.description}
                            source={() => {
                                return this.state.allPeople
                            }}
                            onSave={(content) => {
                                this.setState({ newComment: false, editComment: false })
                                Querys.Card.CommentSave(this.state.id, { description: content })
                                    .then(({ data }) => {
                                        data.attachments = []
                                        this.state.cardComments.unshift(data)
                                        this.setState({ ...this.state })
                                    })
                            }}
                            onCancel={() => {
                                this.setState({ newComment: false, editComment: false })
                            }}
                            onOpen={() => this.setState({ newComment: false, editComment: true })}
                            placeholder="Adicione um comentário..." />
                        {this.state.editComment === false && this.state.newComment === false && <small>aperte [ctrl + M] para fazer um novo comentário</small>}
                    </>}
                />
                {this.state.cardComments?.map(item =>
                    <React.Fragment key={`card-comment-${item.id}`}>
                        <CardComment {...item}
                            cardKey={this.state.cardKey}
                            allPeople={this.state.allPeople}
                            onDelete={(comment) => this.setState({ isOpen: true, commentDelete: comment })} />
                    </React.Fragment>
                )}
                <Modal
                    isOpen={this.state.isOpen}
                    toggle={this.closeModal}
                    className="modal-dialog-centered">
                    <ModalHeader>
                        <ModalTitle appearance="danger">
                            Excluir este comentario?
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <p>
                            Uma vez excluído, não é possível recuperar.
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button appearance="subtle" className='atl-button' onClick={this.closeModal}>Cancelar</Button>
                        <Button autoFocus className='atl-button' appearance="danger"
                            onClick={() =>
                                Querys.Card.CommentDelete(this.state.commentDelete)
                                    .then(() => {
                                        const index = this.state.cardComments.findIndex(x => x.id === this.state.commentDelete)
                                        this.state.cardComments.splice(index, 1)
                                        this.setState({ cardComments: this.state.cardComments })
                                        this.closeModal()
                                    })} >
                            Excluir
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
