import { ToastError } from "./ToastMessages"
import { history } from "../history"

const userKey = '_suricatoo_user'
function errorHandle (error) {
    console.log(error.response)
    //AO DAR ERRO 401, O SISTEMA DEVERÁ NOTIFICAR O USUÁRIO E FAZER LOGOUT APOS 5 SEGUNDOS
    if(error && error.response && error.response.status === 401){
        ToastError("Sua sesão expirou. Iremos te redirecionar para o login e 5 segundos.")
        localStorage.removeItem(userKey)
        setTimeout(() => {
            history.push("/pages/login")
        }, 5000)
        return {
            message: "Sua sesão expirou. Iremos te redirecionar para o login e 5 segundos.",
            code: error.response.status
        }
    } else if(error && error.response && error.response.status !== 401) {
        let errorMessage = error && error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Ocorreu um erro ao processar sua solicitação'
        return {
            message: errorMessage,
            code: error.response.status
        }    
    } else {
        return {
            message: 'Ocorreu um erro ao processar sua solicitação',
            code: 0
        }    
    }

}
export {
    errorHandle
}