import React, { Component } from 'react'
import Checkbox from "../../../../../components/@vuexy/checkbox/CheckboxesVuexy";
import * as Icon from "react-feather"
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import Textfield from '@atlaskit/textfield';
import Badge from '@atlaskit/badge';
import MoreIcon from '@atlaskit/icon/glyph/more';
import CrossIcon from '@atlaskit/icon/glyph/cross'
import CheckIcon from '@atlaskit/icon/glyph/check'
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled'
import Spinner from '@atlaskit/spinner';
import "../projectBacklog.scss"
import { Querys } from '../../../../../database/base';
import DropdownPeopleSelect from './DropdownPeopleSelect';
import DropdownWorkPlanStatus from './DropdownWorkPlanStatus';
import Lozenge from '@atlaskit/lozenge';
import TextColor from '../../../../../utility/Color';
import { TimerUtils } from '../../../../../utility/TimerConvertUtils';
import DropdownChangeCardType from '../../../card/components/DropdownChangeCardType';
import InlineEdit from '@atlaskit/inline-edit';
import { Label } from 'reactstrap';
import CheckBoxesVuexy from '../../../../../components/@vuexy/checkbox/CheckboxesVuexy';
import InlineDialog from '@atlaskit/inline-dialog';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large'
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down'

const app = JSON.parse(localStorage.getItem('_suricatoo_app'))
const user = JSON.parse(localStorage.getItem('_suricatoo_user'))
let accessToken = user?.access_token ?? ''
if (accessToken !== '') {
    accessToken = `?p=${accessToken}`
}

export default class ItemLine extends Component {
    state = {
        name: '',
        cardKey: '',
        done: false,
        selected: false,
        tagsEdit: false,
        tagsEditLoading: false,
        tagsData: [],
        tags: []
    }

    componentWillMount() {
        const { project } = this.props
        const { name, cardKey, cardType, origin, backlog, parentCardID } = this.props.item
        if (cardKey.indexOf('newItem') >= 0) {
            Querys.Card.Create({ name, parentCardID, cardType, projectID: project?.id, projectKey: project?.key, origin: origin ?? 'backlog', backlog: backlog ?? false })
                .then(({ data }) => {
                    if (this.props.onCardCreate) this.props.onCardCreate(data)
                    this.setState({ ...data })
                })
        }
        this.setState({ ...this.props.item, tags: this.props.item?.tags ?? [] })
    }

    save = (value) => {
        Querys.Card.UpdateFields(this.state.id, value)
        this.setState({ edit: !this.state.edit })
    }

    componentDidUpdate() {
        if (!this.props.multiSelect && this.state.selected) {
            this.setState({ selected: false })
        }
        if (this.props.item.closeAllTags > (this.state.closeAllTags ?? 0)) {
            this.setState({ tagsEdit: false, closeAllTags: this.props.item.closeAllTags })
        }
    }

    toogle = () => {
        if (this.props.toogle && !this.props.multiSelect)
            this.props.toogle()
    }

    render() {
        let { cardKey, cardType, id } = this.state
        if (cardKey.indexOf('newItem') >= 0) {
            cardKey = <Spinner />
        }
        const { subCards } = this.props.item
        let totalSubcards = 0
        let totalPlannedTime = 0
        if (this.state.subCards?.length > 0) {
            this.state.subCards.forEach(x => {
                if (x.todo) totalSubcards += x.todo.time
                totalPlannedTime += x.plannedTime
            })
        }
        return <div key={`dv-item-line-card-${this.props.item.id}`} className={`d-flex justify-content-between border-botton task-line ${this.props.item.id === 0 ? 'line-heidth' : ''} ${this.props.multiSelect && 'line-multiselect'} ${this.props.multiSelect && this.state.selected && 'line-selected'}`}
            onClick={() => {
                if (this.props.multiSelect) {
                    this.props.onCardSelected(!this.state.selected, id)
                    this.setState({ selected: !this.state.selected })
                }
            }}>
            <div className={`d-flex justify-content-center ${this.state.edit ? 'w-50' : ''}`}>
                <React.Fragment>
                    {this.props.group &&
                        <div className='m-auto-top'>
                            <span className='kanban-mouse-hover' onClick={this.toogle}>
                                {!this.props.item.open && <ChevronRightLargeIcon />}
                                {this.props.item.open && <HipchatChevronDownIcon />}
                            </span>
                        </div>}
                    {this.state.id !== 0 &&
                        <div className={`item-line-edit-cardType ${!this.props.group ? 'item-line-group-margin' : ''}`}>
                            <DropdownChangeCardType
                                cardType={cardType}
                                subCards={0}
                                onClick={(cardType) => {
                                    this.setState({ cardType })
                                    Querys.Card.UpdateFields(this.state.id, { cardType })
                                }} />
                        </div>}
                    <a href={`/card/${cardKey + accessToken}`} target='_blank' className='task-left-item item-line-link-margin'><small><b>{cardKey}</b></small></a>
                    {this.state.edit ?
                        <React.Fragment>
                            <Textfield className='display-none'
                                value={this.state.name}
                                autoFocus={true}
                                onKeyDown={e => {
                                    if (e.keyCode === 27) this.setState({ edit: !this.state.edit, name: this.props.item.name })
                                    if (e.keyCode === 13) this.save({ name: this.state.name })
                                }}
                                onChange={e => this.setState({ name: e.target.value })} />
                            <Button
                                className='buttons-edit-task atl-button'
                                onClick={() => this.setState({ edit: !this.state.edit, name: this.props.item.name })}
                                iconBefore={<CrossIcon />}
                                appearance="default"
                            >
                            </Button>
                            <Button className='buttons-edit-task atl-button'
                                onClick={() => this.save({ name: this.state.name })}
                                iconBefore={<CheckIcon />}
                                appearance="default"
                            >
                            </Button>
                        </React.Fragment> :
                        <React.Fragment>
                            {this.state.id === 0 && <span className='task-left-item' onClick={this.toogle}>{this.state.name}</span>}
                            {this.state.id > 0 && <>
                                <span className='task-left-item' onClick={this.toogle}>{" - " + this.state.name}</span>
                                <div className='task-link-edit'>
                                    <Button className='atl-button'
                                        onClick={() => this.setState({ edit: !this.state.edit })}
                                        iconBefore={<EditFilledIcon label="" size="small" />}
                                        appearance="default"
                                    >
                                    </Button>
                                </div>
                            </>}
                        </React.Fragment>}
                    {/* {subCards?.length > 0 &&
                        <React.Fragment>
                            <div style={{ marginLeft: '5px' }} className='m-auto-top' onClick={this.toogle}>
                                <Tooltip content='Subcards'>
                                    (<span>{subCards.filter(x => x.done).length}/{subCards.length}</span>)
                                </Tooltip>
                            </div>
                        </React.Fragment>} */}
                </React.Fragment>

            </div>
            <table>
                <tbody>
                    <tr>
                        {this.state.projectID &&
                            <td>
                                <div className='board-card-tags inline-edit-tags' >
                                    <InlineDialog
                                        isOpen={this.state.tagsEdit}
                                        placement='top-start'
                                        onClose={() => this.setState({ tagsEdit: false })}
                                        content={<div>
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <h6>Tags</h6>
                                                </div>
                                                <Button iconAfter={<CrossIcon />} onClick={() => this.setState({ tagsEdit: false })}></Button>
                                            </div>
                                            <div className='tags-board-card'>

                                                {this.state.tagsEditLoading && <Spinner interactionName="load" />}
                                                {!this.state.tagsEditLoading && (
                                                    this.state.tagsData.map(tag => {
                                                        return (
                                                            <CheckBoxesVuexy
                                                                key={`check-tags-${tag.id}`}
                                                                color="primary"
                                                                checked={(this.state.tags ?? []).findIndex(x => x.id === tag.id) >= 0}
                                                                label={<Lozenge key={`tag-data-${tag.id}`} style={{ backgroundColor: tag.color, color: TextColor(tag.color) }}>{tag.tag}</Lozenge>}
                                                                icon={<Icon.Check className="vx-icon" size={16} />}
                                                                onChange={() => {
                                                                    const tagIndex = (this.state.tags ?? []).findIndex(x => x.id === tag.id)
                                                                    const { tags } = this.state
                                                                    if (tagIndex >= 0) {
                                                                        tags.splice(tagIndex, 1)
                                                                    } else {
                                                                        tags.push(tag)
                                                                    }
                                                                    this.setState({ tags })
                                                                    Querys.Card.UpdateTags(this.state.id, tags)
                                                                }} />
                                                        )
                                                    })
                                                )}
                                                {!this.state.tagsEditLoading && this.state.tagsData.length === 0 && (
                                                    <small>Nenhuma tag cadastrada parra esse projeto</small>
                                                )}
                                            </div>
                                        </div>}>
                                        <div className='text-right' onClick={() => {
                                            this.props.onCloseAllTags()
                                            this.setState({ tagsEditLoading: true, tagsEdit: true })
                                            Querys.Project.Tags(this.state.projectID).then(({ data }) => {
                                                this.setState({ tagsEditLoading: false, tagsData: data ?? [] })
                                            })
                                        }}>
                                            {this.state.tags.map(tag => {
                                                return (
                                                    <div className='inline-edit-item-tag' key={`tag-item-line-${tag.id}`}>
                                                        <Tooltip content={`Tag: ${tag.tag}`}>
                                                            <Lozenge key={`tag-${tag.id}`} style={{ backgroundColor: tag.color, color: TextColor(tag.color) }}>{tag.tag}</Lozenge>
                                                        </Tooltip>
                                                    </div>
                                                )
                                            })}
                                            {(!this.state.tags || this.state.tags.length === 0) && (
                                                <Tooltip content='Clique para adicionar ou remover tags' position='right'>
                                                    <small>tags</small>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </InlineDialog>
                                </div>
                            </td>}
                        <td>
                            <div className='d-flex' style={{ position: 'relative', marginRight: '5px' }}>
                                <div className='kanban-card-time-item-position'>
                                    <div className={`kanban-card-time-item-line d-flex`}>
                                        {this.props.item.id > 0 &&
                                            <>
                                                {(this.state.subCards?.length ?? 0) === 0 &&
                                                    <InlineEdit
                                                        defaultValue={TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.plannedTime)}
                                                        editView={({ errorMessage, ...fieldProps }) => (
                                                            <Textfield {...fieldProps} autoFocus style={{ width: '50px' }} />
                                                        )}
                                                        readView={() => (
                                                            <Tooltip content='Tempo planejado'>
                                                                <Badge>{this.state.plannedTime ? TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.plannedTime) : '0:00'}</Badge>
                                                            </Tooltip>
                                                        )}
                                                        onConfirm={(value) => {
                                                            Querys.Card.UpdateFields(this.state.id, { plannedTime: parseInt(TimerUtils.tempoToMinutos(value)) })
                                                            this.setState({ plannedTime: parseInt(TimerUtils.tempoToMinutos(value)) })
                                                            if (this.props.onChangeValue) this.props.onChangeValue(this.state.id, { plannedTime: parseInt(TimerUtils.tempoToMinutos(value)) }, this.props.dropName)
                                                        }}
                                                    />}
                                                {(this.state.subCards?.length ?? 0) > 0 &&
                                                    <InlineEdit
                                                        isEditing={false}
                                                        defaultValue={TimerUtils.HoraMinuto.MinutosToHoraMinuto(this.state.plannedTime)}
                                                        editView={({ errorMessage, ...fieldProps }) => (
                                                            <Textfield {...fieldProps} autoFocus style={{ width: '50px' }} />
                                                        )}
                                                        readView={() => (
                                                            <Tooltip content='Tempo planejado'>
                                                                <Badge>{totalPlannedTime > 0 ? TimerUtils.HoraMinuto.MinutosToHoraMinuto(totalPlannedTime) : '0:00'}</Badge>
                                                            </Tooltip>
                                                        )}
                                                        onConfirm={(value) => {
                                                            Querys.Card.UpdateFields(this.state.id, { plannedTime: parseInt(TimerUtils.tempoToMinutos(value)) })
                                                            this.setState({ plannedTime: parseInt(TimerUtils.tempoToMinutos(value)) })
                                                        }}
                                                    />}

                                                {(this.state.todo?.time || this.state.todo?.time === 0 || this.state.start || totalSubcards > 0) &&
                                                    <div className='kanban-card-time-item-line-executado'>
                                                        <Tooltip content='Tempo executado'>
                                                            <Badge appearance='primary'>
                                                                {TimerUtils.HoraMinuto.MinutosToHoraMinuto(totalSubcards > 0 ? totalSubcards : (this.state.todo?.time ?? 0))}
                                                            </Badge>
                                                        </Tooltip>
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </td>

                        {this.props.showProject && this.props.item.project &&
                            <td>
                                <div className='mr-1'>
                                    <Lozenge style={{
                                        backgroundColor: this.props.item.project.color,
                                        color: TextColor(this.props.item.project.color)
                                    }}>{this.props.item.project.name}</Lozenge>
                                </div>
                            </td>}
                        <td>
                            <DropdownWorkPlanStatus data={this.props.workPlanStatus} status={this.state.workPlanStatusType ?? 'todo'}
                                onClick={(status) => {
                                    Querys.Card.UpdateFields(this.state.id, { workPlanStatusType: status })
                                    this.props.onUpdateWorkPlanStatusType(this.state.id, status, this.props.dropName)
                                    this.setState({ workPlanStatusType: status })
                                }} />
                        </td>
                        <td>
                            {this.props.item.id > 0 &&
                                <DropdownPeopleSelect
                                    id={this.state.assigenedToID}
                                    name={this.state.assigened?.name ?? 'Não atribuido'}
                                    data={(this.props.sprint?.participants ?? []).length === 0 ? this.props.people : this.props.sprint?.participants}
                                    onUserSelected={user => {
                                        if (user === null)
                                            Querys.Card.UpdateFields(this.state.id, { removeAssignedTo: true })
                                        else
                                            Querys.Card.UpdateFields(this.state.id, { assigenedToID: user })
                                        this.props.onUpdateAssignedTo(this.state.id, user, this.props.dropName)
                                    }} />
                            }
                        </td>
                        <td style={{ minWidth: '35px' }}>
                            {this.props.item.id > 0 &&
                                <div className='task-button-menu'>
                                    <DropdownMenu
                                        placement='bottom-end'
                                        trigger={({ triggerRef, testId, isSelected, ...props }) => (
                                            <Button
                                                className='atl-button '
                                                {...props}
                                                iconBefore={<MoreIcon label="more" />}
                                                ref={triggerRef}
                                            />
                                        )}
                                    >
                                        <DropdownItemGroup>
                                            <DropdownItem href={`/card/${this.state.cardKey + accessToken}`} target='_blank'>
                                                <span style={{ marginLeft: '3px' }}>Editar</span>
                                            </DropdownItem>
                                            <DropdownItem onClick={this.props.onShare}>
                                                <span style={{ marginLeft: '3px' }}>Compartilhar</span>
                                            </DropdownItem>
                                            {this.props.sprint && this.props.project && this.props.project.boardID !== this.props.boardID &&
                                                <DropdownItem onClick={this.props.onRoolbackBacklog}>
                                                    <span style={{ marginLeft: '3px' }}>Devolver para o backlog</span>
                                                </DropdownItem>}
                                            {this.props.onCloneItem &&
                                                <DropdownItem onClick={this.props.onCloneItem}>
                                                    <span style={{ marginLeft: '3px' }}>Clonar</span>
                                                </DropdownItem>}
                                            {this.props.onDeleteItem && (this.state.todo?.time ?? 0) === 0 &&
                                                <DropdownItem onClick={() => {
                                                    this.props.onDeleteItem(this.props.item)
                                                }}>
                                                    <span style={{ marginLeft: '3px' }}>Excluir</span>
                                                </DropdownItem>}

                                        </DropdownItemGroup>
                                    </DropdownMenu>
                                </div>}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}