import axios from "axios"
import { del, get, post, put } from "./base"

const getUrl = () => {
    // let l = window.location
    // if (l.hostname === 'localhost') return `${l.protocol}//${l.hostname}${config.BaseURL}/category`
    return `https://wiki.minzi.com.br/api`
}

export const GetDocument = (companyCode, document) => {
    return get(`${getUrl()}/document/${companyCode}/${document}`)
}

export const UpdateDocument = (companyCode, document, data) => {
    const auth = axios.defaults.headers.common['Authorization']
    return put(`${getUrl()}/document/${companyCode}/${document}?p=${auth}`, data)
}

export const GetTree = (companyCode) => {
    const auth = axios.defaults.headers.common['Authorization']
    return get(`${getUrl()}/${companyCode}/wiki?p=${auth}`)
}

export const SaveTree = (companyCode, data) => {
    const auth = axios.defaults.headers.common['Authorization']
    return put(`${getUrl()}/${companyCode}/wiki?p=${auth}`, data)
}