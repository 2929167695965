import React, { Component } from 'react'
import { Field } from '@atlaskit/form';
import { Querys } from '../../../../database/base';
import TextColor from '../../../../utility/Color';
import Select from '@atlaskit/select';
import Lozenge from '@atlaskit/lozenge';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import { Col } from 'react-bootstrap';
import Tooltip from '@atlaskit/tooltip';
import { Cache } from '../../cache/cache';

const userKey = '_suricatoo_user'
const user = JSON.parse(localStorage.getItem(userKey))

function getProjectID() {
    return Cache.createCardProject(user?.codigo).get()?.value ?? null
}

export default class ProjectSelect extends Component {
    state = {
        data: [],
        sprints: [],
        tags: [],
        projectSelected: false,
        defaultValue: null
    }
    componentWillMount() {
        Querys.Project.ProjectsPerson(user.codigo).then(({ data }) => this.setState({ data: data.data }))
        if (this.props.defaultValue) {
            Querys.Sprint.ByProject(this.props.defaultValue.value).then(({ data }) => this.setState({ sprints: data }))
        }
        if (this.props.cacheValue) {
            const projectID = getProjectID()
            if (projectID !== null) {
                Querys.Sprint.ByProject(projectID).then(({ data }) => this.setState({ sprints: data }))
            }
        }
    }
    loadAllProject = () => {
        Querys.Project.ListAll().then(({ data }) => this.setState({ data }))
    }
    render() {
        let data = this.state.data
        let defaultValue = this.state.defaultValue
        if (this.props.cacheValue && !this.state.defaultValue) {
            const projectID = getProjectID()
            if (projectID) {
                const item = data.find(x => x.Codigo === projectID)
                if (item) {
                    defaultValue = {
                        label: (
                            <Tooltip content={`${item.Nome} (${item.NomeCliente})`}>
                                <div className='project-select-item' style={{ backgroundColor: item.Color, color: TextColor(item.Color) }}>{item.Nome} ({item.NomeCliente})</div>
                            </Tooltip>
                        ),
                        value: item.Codigo,
                        name: item.Nome
                    }
                }
            }
        }
        return (
            <div>
                <Row>
                    <Col md={this.props.disableSprint ? '12' : '6'}>
                        {!this.props.disableHeader &&
                            <div className='d-flex justify-content-between'>
                                <Field label="Projeto" name="project-select">
                                    {({ fieldProps }) => { }}
                                </Field>
                                <Field name="project-select-load">
                                    {({ fieldProps }) => <Link to='#' className='font-load-all-project' onClick={this.loadAllProject}>Carregar todos os projetos</Link>}
                                </Field>
                            </div>
                        }
                        <div>
                            <Select
                                isClearable
                                className='w-100'
                                value={defaultValue}
                                options={data.map(item => {
                                    return {
                                        label: (
                                            <Tooltip content={`${item.Nome} (${item.NomeCliente})`}>
                                                <div className='project-select-item' style={{
                                                    backgroundColor: item.Color,
                                                    color: TextColor(item.Color),
                                                    minWidth: '250px'
                                                }}>{item.Nome} ({item.NomeCliente})</div>
                                            </Tooltip>
                                        ),
                                        value: item.Codigo,
                                        name: item.Nome,
                                        item
                                    }
                                })}
                                filterOption={(option, rawInput) => {
                                    return option.data.name.toLowerCase().includes(rawInput.toLowerCase())
                                }}
                                placeholder="Escolha o projeto"
                                onChange={e => {
                                    this.props.onChange(e?.value ?? null, e?.label ?? null, e?.item.costCenterName ?? null, e?.item.costCenterID ?? null)
                                    if (e !== null)
                                        Querys.Sprint.ByProject(e.value).then(({ data }) => this.setState({ sprints: data }))
                                    else
                                        this.setState({ sprints: [] })
                                    this.setState({ projectSelected: e ? true : false, defaultValue: e })
                                }}
                            />
                        </div>
                    </Col>
                    {!this.props.disableSprint &&
                        <Col md='6'>
                            <Field label="Sprint" name="sprint-select">
                                {({ fieldProps }) => (
                                    <Select
                                        isClearable
                                        isDisabled={this.state.sprints.length === 0}
                                        className="w-100"
                                        options={this.state.sprints.map(item => {
                                            return {
                                                label: item.name,
                                                value: item.boardID
                                            }
                                        })}
                                        placeholder="Selecione uma sprint"
                                        onChange={e => {
                                            this.props.onSprintChange(e?.value ?? null)
                                        }}
                                    />
                                )}
                            </Field>
                        </Col>}

                </Row>
            </div>
        )
    }
}
